<template>
    <router-view></router-view>
</template>

<script>

export default {
  name: 'MQTT',
  components: {
  },
  data: () => ({
  }),
  computed: {
  }
}
</script>
